<template>
  <div class="relative mb-16">
    <portal to="topbar-left">
      <div
        @click="goBack"
        class="relative font-bold"
        :class="{
          'text-white': isCheckedIn,
          'text-brand-dark': !isCheckedIn,
        }"
        key="info-emergency-back"
      >
        <icon-back class="w-6 h-6" />
      </div>
    </portal>

    <div class="relative px-4 mx-auto">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import IconBack from "assets/icons/back.svg";

export default {
  name: "info-emergency",
  components: {
    IconBack,
  },
  data: () => ({
    showContent: false,
    selectedContent: null,
  }),
  computed: {
    ...mapGetters(["isCheckedIn"]),
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  metaInfo() {
    return {
      title: `${this.$i18n.t("pages.emergency.title")}`,
    };
  },
};
</script>
